.content{
  line-height: 24px;
  padding-bottom: 42px;
  ul:not(.country-list):not(.tabs__caption):not(.order-tabs){
    li{
      position: relative;
      padding-left: 56px;
      margin: 16px 0;
      //text-align: justify;
      &:before{
        content: "";
        display: block;
        width: 18px;
        height: 14px;
        position: absolute;
        top: 4px;
        left: 22px;
        background-image: url(../images/icons/check-1.svg);
        background-repeat:no-repeat;
        background-position: center center;
        -webkit-background-size: cover;
        background-size: cover;
      }
      ul{
        li{
          padding-left: 16px;
          &:before{
            background-image:none;
            width: 7px;
            height: 7px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border:1px solid $gray-soft;
            top: 9px;
            left: 0;
          }
        }
      }
    }
    &.no-icon{
      li{
        padding-left: 0;
        &:before{
          display: none;
        }
      }
    }
  }
  .list-round-type{
    position: relative;
    width:calc(100% - 104px);
    li{
      position: relative;
      padding-left: 16px;
      margin: 6px 0;
      //&:before{
      //  background-image: none;
      //  width: 7px;
      //  height: 7px;
      //  -webkit-border-radius: 50%;
      //  -moz-border-radius: 50%;
      //  border-radius: 50%;
      //  border:1px solid $gray-soft;
      //  top: 9px;
      //  left: 0;
      //}
    }
  }
}
.dms-block-img{
  width: 84px;
  height: 84px;
  margin-right: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  -webkit-background-size: 100%;
  background-size: 100%;
  border:1px solid #ececec;
  background-color: #ececec;
  &.hospital{
    background-image: url(../images/icons/dms-hospital.svg);
  }
  &.doctor{
    background-image: url(../images/icons/dms-doctor.svg);
  }
  &.tooth{
    background-image: url(../images/icons/dms-tooth.svg);
  }
  &.car{
    background-image: url(../images/icons/dms-car.svg);
  }
  &.baby{
    background-image: url(../images/icons/dms-baby.svg);
  }
}

.accordion{
  &__header{
    background: $milky;
    cursor: pointer;
    border-bottom:1px solid $gray-light;
    padding: 12px 42px 12px 16px;
    position: relative;
    &:after{
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background-image: url(../images/icons/expand-more.svg);
      background-repeat:no-repeat;
      background-position: center center;
      -webkit-background-size: cover;
      background-size: cover;
      position: absolute;
      top:50%;
      right:16px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
    }
   &.opened-link{
     &:after{
       -webkit-transform: translateY(-50%) rotate(180deg);
       -moz-transform: translateY(-50%) rotate(180deg);
       -ms-transform: translateY(-50%) rotate(180deg);
       -o-transform: translateY(-50%) rotate(180deg);
       transform: translateY(-50%) rotate(180deg);
     }
   }
  }
  &__content{
    padding: 12px 16px;
    display: none;
  }
}
.review-block{
  border:1px solid $gray-light;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 24px;
  &__title{
    font-size: 24px;
    line-height:29px;
  }
  &__date{
    font-size: 17px;
    line-height:21px;
    margin: 16px 0;
  }
  &__text{
    font-size: 17px;
  }
}
.figured-title{
  font-family: Georgia, serif;
  font-size: 28px;
  line-height: 36px;
  font-weight: normal;
  font-style: italic;
  color: $wine;
  margin: 34px 0 20px;
  padding-bottom: 20px;
  border-bottom:2px solid $wine;
}
.phone-block{
  border:1px solid $gray-light;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  &__title{
    font-size:20px;
    line-height: 24px;
    color: $gray;
    font-weight: bold;
  }
  &__text{
    font-size: 16px;
    line-height: 22px;
    color: $gray;
    margin: 10px 0;
  }
  &__phone{
    color: $wine;
    position: relative;
    display: block;
    padding-left: 26px;
    &:before{
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      left:0;
      top:50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      background-image: url(../images/icons/settings-phone-small-wine.svg);
      -webkit-background-size: 100%;
      background-size: 100%;
      background-repeat:no-repeat;
    }
  }
}

@media (max-width: 1023px){
  .dms-block-img{
    width:64px;
    height:64px;
  }
}
@media (max-width: 767px){
  .dms-block-img{
    width:54px;
    height:54px;
  }
}