.balloon{
  &-title{
    color: $wine;
    font-weight:bold;
    font-family:ProximaNova, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
  }
  &-address{
    font-size: 12px;
    position: relative;
    padding-left: 16px;
    &:before{
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      background-image: url(../images/icons/marker.svg);
      -webkit-background-size: 100%;
      background-size: 100%;
      background-repeat:no-repeat;
    }
  }
  &-tel{
    font-size: 12px;
    position: relative;
    padding-left: 16px;
    &:before{
      content: "";
      display: block;
      width: 9px;
      height: 9px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      background-image: url(../images/icons/settings-phone-small.svg);
      -webkit-background-size: 100%;
      background-size: 100%;
      background-repeat:no-repeat;
    }
  }
}
.vi{
  .balloon{
    &-title{
      color: $black-soft;
    }
  }
}
