.service{
  color: $gray;
  margin-bottom: 30px;
  &-inner{
    width: 100%;
    border:1px solid $gray-light;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 15px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    &__icon{
      width: 82px;
      height:82px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: $gray-light-extra;
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 15px;
      background-repeat:no-repeat;
      background-position: center center;
      &.mortarboard{
        background-image: url(../images/icons/mortarboard.svg);
      }
      &.mortgage{
        background-image: url(../images/icons/mortgage.svg);
      }
      &.taxi{
        background-image: url(../images/icons/taxi.svg);
      }
      &.heart{
        background-image: url(../images/icons/heart.svg);
      }
      &.insurance{
        background-image: url(../images/icons/insurance.svg);
      }
      &.travel{
        background-image: url(../images/icons/travel.svg);
      }
      &.warning{
        background-image: url(../images/icons/warning.svg);
        background-color: $wine;
      }
      &.dsago{
        background-image: url(../images/icons/dsago.svg);
      }
      &.docs{
        background-image: url(../images/icons/docs.svg);
      }
      &.evacuator{
        background-image: url(../images/icons/evacuator.svg);
      }
      &.apart{
        background-image: url(../images/icons/apart.svg);
      }
      &.mortgage-percent{
        background-image: url(../images/icons/mortgage-percent.svg);
      }
      &.brush{
        background-image: url(../images/icons/brush.svg);
      }
      &.armchair{
        background-image: url(../images/icons/armchair.svg);
      }
      &.criminal{
        background-image: url(../images/icons/criminal.svg);
      }
      &.cars{
        background-image: url(../images/icons/cars.svg);
      }
      &.gruz{
        background-image: url(../images/icons/gruz.svg);
      }
      &.flash{
        background-image: url(../images/icons/flash.svg);
      }
      &.stihiya{
        background-image: url(../images/icons/stihiya.svg);
      }
      &.rearview-mirror{
        background-image: url(../images/icons/rearview-mirror.svg);
      }
      &.without-docs{
        background-image: url(../images/icons/without-docs.svg);
      }
      &.driver-license{
        background-image: url(../images/icons/driver-license.svg);
      }
      &.icons-dtp{
        background-image: url(../images/icons/icons-dtp.svg);
      }
      &.gavel{
        background-image: url(../images/icons/gavel.svg);
      }
      &.builder{
        background-image: url(../images/icons/builder.svg);
      }
      &.crane{
        background-image: url(../images/icons/crane.svg);
      }
      &.concrete-mixer{
        background-image: url(../images/icons/concrete-mixer.svg);
      }
      &.team{
        background-image: url(../images/icons/team.svg);
      }
      &.moving-truck{
        background-image: url(../images/icons/moving-truck.svg);
      }

    }
    &__text{
      display: inline-block;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      font-size: 17px;
    }
    &:before{
      content: "";
      display: block;
      width: 0;
      height: 100%;
      top:0;
      left:0;
      position: absolute;
      border-top:1px solid $wine;
      border-left:1px solid $wine;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      opacity:0;
    }
    &:after{
      content: "";
      display: block;
      width: 0;
      height: 100%;
      top:0;
      right:0;
      position: absolute;
      border-bottom:1px solid $wine;
      border-right:1px solid $wine;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      opacity:0;
    }
    &:hover{
      .service-inner__text{
        color: $wine;
        //transition-delay:0.5s;
        //transition-duration:0.3s;
      }
      &:before{
        width: 100%;
        opacity:1;
      }
      &:after{
        width: 100%;
        opacity:1;
      }
    }
    &-no-border{
      border: 1px solid transparent!important;
      &:before, &:after, &:hover:before, &:hover:after{
        display: none;
      }
      .service-inner__icon{
        background-color: transparent;
      }
    }
  }
  &:last-child{
    .service-inner{
      border: 1px solid $wine;
    }
  }
}
.services{
  align-items:stretch;
  &.half-services{
    margin-top: 40px;
    margin-bottom: -20px;
    .service{
      color: $gray;
      margin-bottom: 30px;
      &:last-child{
        .service-inner{
          border:1px solid $gray-light;
        }
      }
    }
    .service-inner__text{
      text-align: center;
      font-size: 17px;
      hyphens: auto;
      div{
        color: $wine;
        font-size: 20px;
        font-weight:bold;
      }
      &.small-text{
        font-size: 14px;
      }
    }
  }
}

.services-header{
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 40px;
  &-item{
    font-family:Georgia, sans-serif;
    position: relative;
    font-style: italic;
    font-size: 22px;
    padding: 20px 20px 10px 20px;
    display: block;
    color: $gray;
    text-align: center;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    &:before{
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      left:0;
      bottom:0;
      background-color: $gray-light;
    }
    &:after{
      content: "";
      display: block;
      width: 0;
      height: 3px;
      position: absolute;
      left:0;
      bottom:0;
      background-color: $wine;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
    }
    &.active{
      color: $wine;
      &:before{
        background-color:$wine;
      }
    }
    &:hover{
      color: $wine;
      &:after{
        width: 100%;
      }
    }
  }
}

.contacts-header{
  margin-bottom: 40px;
  &-item{
    font-family:Georgia, sans-serif;
    position: relative;
    font-style: italic;
    font-size: 18px;
    padding: 20px 20px 10px 20px;
    display: block;
    color: $gray;
    text-align: center;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    //&:before{
    //  content: "";
    //  display: block;
    //  width: 100%;
    //  height: 1px;
    //  position: absolute;
    //  left:0;
    //  bottom:0;
    //  background-color: $gray-light;
    //  background-color: green;
    //}
    //&:after{
    //  content: "";
    //  display: block;
    //  width: 0;
    //  height: 1px;
    //  position: absolute;
    //  left:0;
    //  bottom:0;
    //  background-color: $wine;
    //  -webkit-transition: 0.3s;
    //  -moz-transition: 0.3s;
    //  -ms-transition: 0.3s;
    //  -o-transition: 0.3s;
    //  transition: 0.3s;
    //}
    //&.active{
    //  color: $wine;
    //  &:before{
    //    background-color:$wine;
    //  }
    //}
    //&:hover{
    //  color: $wine;
    //  &:after{
    //    width: 100%;
    //  }
    //}
  }
}
.tabs{
  margin-top: 60px;
  margin-bottom: 60px;
  &__caption{
    cursor: pointer;
  }
  &__content{
    display: none;
    &.active{
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.order-tabs{
  &__content{
    display: none;
    &.active{
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.content{
  margin-top: -30px;
  .tabs{
    margin-top: 0;
    margin-bottom: 20px;
    &__caption{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 30px;
      li{
        margin-top: 0;
        margin-bottom: 20px;
        margin-right: 20px;
        color: $wine;
        border:1px solid transparent;
        padding: 2px 16px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        &.active{
          border:1px solid $wine;
        }
        &:before{
          display: none;
        }
        &:after{
          display: none;
        }
      }
    }
    &__content{
      &.active{
        display: block;
      }
    }
    &.tabs-index{
      ul.tabs__caption{
        margin-bottom: 0px;
        li{
          color: $gray;
          border:none;
          margin-right: 0;
          &:before{
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            top:auto;
            position: absolute;
            left:0;
            bottom:0;
            background-color: $gray-light;
            background-image: none;
          }
          &:after{
            content: "";
            display: block;
            width: 0;
            height: 1px;
            position: absolute;
            left:0;
            bottom:0;
            background-color: $wine;
            -webkit-transition: 0.3s;
            -moz-transition: 0.3s;
            -ms-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
          }
          &.active{
            color: $wine;
            &:before{
              background-color:$wine;
            }
          }
          &:hover{
            color: $wine;
            &:after{
              width: 100%;
            }
          }
        }
      }
    }

  }
  .btn{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .new-order-result__under{
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
    .btn{
      margin-top: 0;
      margin-bottom: 0;
      flex-shrink: 0;
    }
  }
  .error{
    display: none;
  }
  .order-tabs{
    counter-reset: item;
    justify-content: space-between;
    margin:0 70px;
    position: relative;
    display: flex;
    li{
      border: none;
      font-size: 14px;
      color: $black-soft;
      padding: 0;
      margin: 0;
      z-index:1;
      &:before{
        content: counter(item) " ";
        counter-increment: item;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 36px;
        height: 36px;
        font-size: 22px;
        border-radius: 50%;
        background-color: $milky;
        margin: 0 auto 6px auto;
      }
      &.active{
        border: none;
        &:before{
          background-color: $wine;
          color: $white;
        }
      }
    }
    &:after{
      content: "";
      display: block;
      width: 80%;
      height: 2px;
      background-color: $gray-light1;
      position: absolute;
      left:50%;
      transform: translateX(-50%);
      top:20px;
      z-index:0;
    }
  }
  .order-tabs{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.order-total-btns{
  display: flex;
  justify-content: center;
  > div{
    margin-left: 10px;
    margin-right: 10px;
  }
  > button{
    margin-left: 10px;
    margin-right: 10px;
  }
  > input{
    margin-left: 10px;
    margin-right: 10px;
  }
}
.order-total{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h4{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.order-last-step{
  h4{
    margin-right: 0;
    text-align: center;
    width: 100%;
  }
}

.qa-block{
  margin-top: 30px;
  &__inner{
    padding: 30px 20px;
    border:1px solid $gray-soft;
    flex-direction: column;
    display: flex;
  }
  .service-inner__content{
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .service-inner__icon{
    margin-right: 30px!important;
    position: relative;
    span{
      position: absolute;
      font-size: 46px;
      font-family:ProximaNova, sans-serif;
      color: $wine;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  &__header{
    font-family: Georgia;
    margin-bottom: 20px;
    text-align: center;
    font-size: 29px;
    font-style: italic;
    color: $wine;
  }
}
.evacuation{
  width: 160px;
  margin-top: 20px;
  .service-inner{
    border:1px solid $gray-soft;
    flex-direction: column;
    text-align: center;
    &:before{
      display: none;
    }
    &:after{
      display: none;
    }
    .service-inner__icon{
      margin-right: 0;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .service-inner__text-phone{
      position: relative;
      font-size: 12px;
      &:before{
        content: "";
        display: block;
        width: 12px; ;
        height: 12px;
        position: absolute;
        top:50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left:-12px;
        background-image: url(../images/icons/settings-phone-small.svg);
        background-repeat:no-repeat;
      }
    }
    &:hover{
      .service-inner__text-phone{
        color: $black;
      }
      &:before{
        display: none;
      }
      &:after{
        display: none;
      }
    }
  }
}
.left-text{
  width:calc(100% - 160px);
  padding-right: 20px;
}
.choose-car-block{
  margin-bottom: 40px;
  width: 60%;
}
.contacts-item{
  &__infoblock{
    div{
      font-size: 16px;
      padding-left: 30px;
      line-height: 30px;
      position: relative;
      &:before{
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        left:0;
        top:50%;
        -webkit-background-size: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
  &__address{
    &:before{
      background-image: url(../images/icons/marker-gray.svg);
    }
  }
  &__tel{
    &:before{
      background-image: url(../images/icons/phone-gray.svg);
    }
  }
  &__fax{
    &:before{
      background-image: url(../images/icons/printer.svg);
    }
  }
  &__email{
    color: $wine;
    &:before{
      background-image: url(../images/icons/email.svg);
    }
  }
}
.traveler{
  &-title{
    justify-content: space-between;
  }
}
.new-order-result{
  width:100%;
}
.content-404{
  &.content{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 118px;
    padding-bottom: 118px;
  }
  margin-left: auto;
  margin-right: auto;
  max-width: 950px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family:'ProximaNova', sans-serif;
  height:100vh;
  &__left{
    color: $wine;
    font-size: 230px;
    line-height: 230px;
    font-weight: bold;
    margin-right: 40px;
  }
  &__right{
    display: flex;
    flex-direction: column;
  }
  &__title{
    font-size: 36px;
    color: $wine;
  }
  &__text{
    font-size: 25px;
    font-weight: 600;
    color: $gray;
    margin: 16px 0;
  }
  &__notice{
    font-size: 16px;
    color: $gray;
    a{
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
.types-wr{
  display: flex;
  align-items: flex-start;
}
#fileuploader form{
  cursor: pointer;
}
.ajax-upload-dragdrop input,
input[type="file"]{
  cursor: pointer;
}
.ajax-file-upload{
  cursor: pointer!important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .content ul:not(.country-list):not(.tabs__caption) li::before{
    background-image: none!important;
  }
  .content .order-tabs li{
    width: 33%;
    z-index:3;
    text-align: center;
  }
  .content .order-tabs:after{
    width: 70%;
  }
}

@media (max-width: 1199px){
  .services{
    &.half-services{
      .service-inner{
        &__text{
          font-size: 15px;
          line-height: 17px;
        }
      }
    }
  }
  .types-wr{
    flex-direction: column;
    display: flex;
    width: 100%;
  }
  .traveler{
    .d-flex{
      align-items: flex-start!important;
      justify-content: flex-start!important;
    }
    &-title{
      flex-direction: column;
      h3{
        margin-bottom: 0;
      }
    }
  }
  .trip-form{
    .half-width.label-half-width{
      width: 100%;
    }
    .passport-serial{
      width: 18%;
      margin-bottom: 20px;
      margin-right: 12px;
    }
    .passport-number{
      width: 30%;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 991px) {
  .content-404{
    &__left{
      font-size: 160px;
      line-height: 160px;
    }
    &__right{

    }
    &__title{
      font-size: 30px;
    }
    &__text{
      font-size: 20px;
    }
    &__notice{

    }
  }
  .content .order-tabs{
    margin-top: 60px;
  }
}
@media (max-width: 767px){
  .choose-car-block{
    width: 100%;
  }
  .left-text{
    width:100%;
  }
  .evacuation{
    width:100%;
    .service-inner{
      display: block;
      width: 100%;
      &__icon{
        vertical-align: middle;
        margin-left: 20px;
        margin-right: 20px;
      }
      &__text{
        vertical-align: middle;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
  .content-404{
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    &__left{
      margin-right: auto;
      margin-left: auto
    }
    &__right{
      text-align: center;
    }
  }
  .trip-form{
    label:not(#for-sport){
      max-width: 100%;
      width: 100%;
      justify-content: space-between;
      input{
        width: 100%;
      }
    }
    .mb-0{
      margin-bottom: 20px;
    }
  }
  .trip-dates{
    flex-direction: column;
    width: 100%;
    span{
      width: 10%;
    }
  }
  .members{
    width: 100%;
  }
  #trip-date-begin,
  #trip-date-end{
    width: 100%;
    margin-left: 10px;
    margin-right: 0;
  }
  #trip-days-amount{
    margin-left: 10px;
    margin-right: 0;
  }
  .trip-form{
    .passport-serial{
      width: 30%;
    }
    .passport-number{
      width: 70%;
    }
  }
  .new-order-result{
    flex-direction: column;
    align-items: flex-start;
  }
  .content .order-tabs{
    margin-left: 0;
    margin-right: 0;
  }
  .member{
    flex-direction: column;
    align-items: flex-end;
    border-bottom:1px solid $gray-light1;
    margin-bottom: 30px;
  }
  .member-item{
    width: 100%;
  }
  .sport-search{
    width: 100%;
    margin-right: 0;
  }
  .member-delete{

  }
  .checkbox.sport-all{
    position: absolute;
    bottom: -2px;
    right: 0;
    width:100%;
  }
}
@media (max-width: 580px) {
  .qa-block{
    text-align: justify;
    &__inner{
      flex-direction: column;
    }
    .service-inner__content{
      flex-direction: column;
    }
    .service-inner__icon{
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .content{
    .order-tabs{
      align-items: flex-start;
      li{
        width: 30%;
        padding: 0 10px;
        text-align: center;
        line-height: 16px;
      }
      &:after{
        width: 70%;
      }
    }
  }
}
@media (max-width: 480px) {
  .services{
    &.half-services{
      .service{
        .service-inner{
          flex-direction: column;
          &__icon{
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .content-404{
    &__left{
      font-size: 100px;
      line-height: 100px;
    }
  }
  .order-total-btns{
    flex-direction: column;
    align-items: center;
  }
  .content{
    .new-order-result__under{
      flex-direction: column;
    }
  }
}
