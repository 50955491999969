.search-input-content{
  display: flex;
  align-items: center;
  .btn{
    width: 112px;
    text-align: center;
    height: 52px;
  }
  input[type="text"]{
    max-width:446px;
    margin-right: 20px;
    height: 52px;
  }
}
.search-block-content{
  border:1px solid $gray-light;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 24px;
  &__title{
    font-size:17px;
    line-height:21px;
    text-decoration: underline;
  }
  &__text{
    font-size: 17px;
    margin: 16px 0;
  }
  &__path{
    font-size: 17px;
    line-height:21px;
    a{
      text-decoration: underline;
    }
    &-separator{
      color: $wine;
    }
  }
}
