@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .vi{
    .service{
      &-inner{
        &__icon {
          filter: none;
          background-color: #222222;
          &.mortarboard {
            background-image: url(../images/iconsie/mortarboard.svg);
          }
          &.mortgage {
            background-image: url(../images/iconsie/mortgage.svg);
          }
          &.taxi {
            background-image: url(../images/iconsie/taxi.svg);
          }
          &.heart {
            background-image: url(../images/iconsie/heart.svg);
          }
          &.insurance {
            background-image: url(../images/iconsie/insurance.svg);
          }
          &.travel {
            background-image: url(../images/iconsie/travel.svg);
          }
          &.warning {
            background-image: url(../images/iconsie/warning.svg);
            background-color: #222222;
          }
          &.dsago {
            background-image: url(../images/iconsie/dsago.svg);
          }
          &.docs {
            background-image: url(../images/iconsie/docs.svg);
          }
          &.evacuator {
            background-image: url(../images/iconsie/evacuator.svg);
          }
          &.apart {
            background-image: url(../images/iconsie/apart.svg);
          }
          &.mortgage-percent {
            background-image: url(../images/iconsie/mortgage-percent.svg);
          }
          &.brush {
            background-image: url(../images/iconsie/brush.svg);
          }
          &.armchair {
            background-image: url(../images/iconsie/armchair.svg);
          }
          &.criminal {
            background-image: url(../images/iconsie/criminal.svg);
          }
          &.cars {
            background-image: url(../images/iconsie/cars.svg);
          }
          &.gruz {
            background-image: url(../images/iconsie/gruz.svg);
          }
          &.flash {
            background-image: url(../images/iconsie/flash.svg);
          }
          &.stihiya {
            background-image: url(../images/iconsie/stihiya.svg);
          }
          &.rearview-mirror {
            background-image: url(../images/iconsie/rearview-mirror.svg);
          }
          &.without-docs {
            background-image: url(../images/iconsie/without-docs.svg);
          }
          &.driver-license {
            background-image: url(../images/iconsie/driver-license.svg);
          }
          &.icons-dtp {
            background-image: url(../images/iconsie/icons-dtp.svg);
          }
          &.gavel {
            background-image: url(../images/iconsie/gavel.svg);
          }
          &.builder {
            background-image: url(../images/iconsie/builder.svg);
          }
          &.crane {
            background-image: url(../images/iconsie/crane.svg);
          }
          &.concrete-mixer {
            background-image: url(../images/iconsie/concrete-mixer.svg);
          }
          &.team {
            background-image: url(../images/iconsie/team.svg);
          }
          &.moving-truck {
            background-image: url(../images/iconsie/moving-truck.svg);
          }
        }
      }
    }
  }
  label.upload-file:after{
    background-image: url(../images/iconsie/upload.svg)!important;
  }
  .phone-block__phone:before{
    background-image: url(../images/icons/settings-phone-small.svg);
  }
  .dms-block-img{
    border:1px solid #222222;
    background-color: #222222;
    &.hospital{
      background-image: url(../images/iconsie/dms-hospital.svg);
    }
    &.doctor{
      background-image: url(../images/iconsie/dms-doctor.svg);
    }
    &.tooth{
      background-image: url(../images/iconsie/dms-tooth.svg);
    }
    &.car{
      background-image: url(../images/iconsie/dms-car.svg);
    }
    &.baby{
      background-image: url(../images/iconsie/dms-baby.svg);
    }
  }
  .qa-block .service-inner__icon span{
    color: #f2f2f2;
  }
  .content ul:not(.country-list):not(.tabs__caption) li:before{
    background-image: url(../images/iconsie/check-1.svg);
  }
  .doc{
    background-image: url(../images/iconsie/doc.svg)!important;
  }

  .footer__menu-download li a:before{
    background-image: url(../images/iconsie/pdf.svg);
  }

}


