//$primary-color: #20b49c;
$primary-color: $milky;
$primary-color-dark: darken( $primary-color, 10% );
$primary-color-tint-1: lighten( $primary-color, 10% );
$primary-color-tint-2: lighten( $primary-color, 20% );
$primary-color-tint-3: lighten( $primary-color, 30% );
$primary-color-tint-4: lighten( $primary-color, 40% );
$primary-color-tint-5: lighten( $primary-color, 50% );
$primary-font: "Fira Sans", Arial, sans-serif;

$link-color: $primary-color;

* { box-sizing: border-box; }
//body {
//   &::after {
//    content: '';
//    position: absolute;
//    z-index: 99;
//    background-color: rgba($primary-color-dark, 0.8);
//    height: 100vh;
//    width: 100vw;
//    transition: 0.4s;
//    opacity: 0;
//    visibility: hidden;
//  }
//}
a {
  text-decoration: none;
}
.nav-top {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 101;
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  background-color: $primary-color-dark;

  .hamburger {
    margin-left: auto;
    color: $white;
    cursor: pointer;
  }
}
.nav-drill {
  transform: translateX(-110%);
  -webkit-box-shadow: 0 1px 20px -2px $gray;
  -moz-box-shadow: 0 1px 20px -2px $gray;
  box-shadow: 0 1px 20px -2px $gray;
  #left-menu__inner{
    ul{
      li{
        a{
          display: block;
          padding: .875em 40px;
          background-color: #fff;
          font-size: 1rem;
          line-height: 1.5em;
        }
      }
    }
    .btn{
      width: 70%;
      margin: 10px 40px;
      text-align: center;
    }
  }
}
.nav-drill-close{
  position: absolute;
  top: 15px;
  right: 21px;
  width: 14px;
  height: 14px;
  background-image: url(../images/icons/close.svg);
  z-index:2;
}
.nav-is-toggled {
  .nav-drill {
    transform: translateX(0);
  }
  &::after {
    opacity: 1;
    visibility: visible;
  }
}

// ---------------------------------------
// important stuff starts here

//$nav-width: 250px;
$nav-width: 50%;
$nav-padding: 0.875em 40px;
$nav-border-color: $primary-color-tint-5;
$nav-background-color: $white;
$nav-icon-font: 'Material Icons';
$nav-icon-arrow-right: 'chevron_right';
$nav-icon-arrow-left: 'chevron_left';

$nav-level-border-color: $primary-color;
$nav-level-background-color: $primary-color-tint-5;

$nav-level3-background-color: $primary-color-tint-4;
$nav-level4-background-color: $primary-color-tint-3;
$nav-level5-background-color: $primary-color-tint-2;

.nav {
  &-drill {
    //padding-top: 40px;
    display: flex;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: $nav-width;
    height: 100vh;
    background-color: $nav-background-color;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    transition: 0.45s;
    li{
      a{
        border-left:5px solid $white;
      }
      .nav-back-link{
        border-left:5px solid $wine;
      }
    }
  }

  &-items {
    flex: 0 0 100%;
    padding-top: 44px;
  }

  &-item {
      border-bottom: solid 1px rgba(151, 151, 151, 0.35);
  }

  &-link {
    display: block;
    padding: $nav-padding;
    background-color: $nav-background-color;
    color: $gray-dark;
    font-size: 1rem;
    line-height: 1.5em;
  }

  &-expand {
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(100%);
      background-color: $nav-level-background-color;
      transition: 0.3s;
      visibility: hidden;

      .nav-item {
          //border-bottom: solid 1px $gray-soft;
      }

      .nav-link {
        background-color: $nav-level-background-color;
      }

      .nav-back-link {
        display: flex;
        align-items: center;
        background-color: $primary-color !important;
        color: $wine-dark;
        padding-left:12px;
        &::before {
          width: 24px;
          height: 24px;
          content: "";
          margin-right: 0.5em;
          //font-family: $nav-icon-font;
          background-image: url(../images/icons/chevron_left_wine.svg);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    &-link {
      color: $wine-dark;
      display: flex;
      position: relative;
      justify-content: space-between;
      background: $milky;
      &::after {
        width: 24px;
        height: 24px;
        content: "";
        flex: 0 1 auto;
        position: absolute;
        right:15px;
        top:50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        //font-family: $nav-icon-font;
        background-image: url(../images/icons/chevron_right_wine.svg);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &.active {
      > .nav-expand-content {
        transform: translateX(0);
        visibility: visible;
        z-index:2;
      }
    }

    // quick example for other level colours
    .nav-expand-content {
      background-color: $nav-level-background-color;
      .nav-link {
        background-color: $nav-level-background-color;
      }
      .nav-expand-content {
        background-color: $nav-level3-background-color;
        .nav-link {
          background-color: $nav-level3-background-color;
        }
        .nav-expand-content {
          background-color: $nav-level4-background-color;
          .nav-link {
            background-color: $nav-level4-background-color;
          }
          .nav-expand-content {
            background-color: $nav-level5-background-color;
            .nav-link {
              background-color: $nav-level5-background-color;
            }
          }
        }
      }
    }
  }
}

@media(max-width: 768px){
  .nav {
    &-drill {
      width:75%;
    }
  }
}
@media(max-width: 500px){
  .nav {
    &-drill {
      width:100%;
    }
  }
}