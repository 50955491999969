.left-menu{
  background-color: $white;
  webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 40px;
  li{
    border-left:3px solid $white;
    border-bottom:1px solid rgba($gray-soft, 0.35);
    display: block;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    a, span{
      padding: 10px 20px;
      font-size: 15px;
      color: $gray-dark;
      font-weight: 600;
      text-transform: none;
      display: block;
      width:100%;
      height:100%;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -ms-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
    }
    &.active{
      background: $milky;
      border-bottom:none;
      a, span{
        color: $wine-dark;
        border-left:3px solid $wine-dark;
      }
      &:hover{
        a{
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
  .left-submenu{
    li{
      border-bottom:none;
      a{
        border-left: none;
        color: $gray-dark;
      }
      padding-left: 20px;
      &.active{
        a{
          color: $wine-dark;
        }
      }
    }
  }
  li{
    a{
      &:hover{
        padding-left: 24px;
        padding-right: 16px;
        color: $wine-dark;
        //border-left: 3px solid $wine-dark;
      }

    }
  }
  .btn{
    width: 100%;
    text-align: center;
    margin-top: 14px;
  }
}
#nav-drill{
  #left-menu__inner{
    ul{
      li{
        border-top:1px solid rgba($gray-soft, 0.35);
        a{
          color: $gray;
        }
        &.active{
          a{
            color: $wine-dark;
          }
        }
      }
      .left-submenu{

        li{

          padding-left: 30px;
          a{
            color: $gray;
          }
          &.active{
            a{
              color: $wine-dark;
            }
          }
        }
      }
    }
  }
  .nav-item{
    &.active{
      > a{
        color: $wine;
      }
    }
  }
}

@media(max-width: 991px){
  .left-menu{
    margin-bottom: 0;
  }
}