/*  Date pickup  */

.pickmeup {
  background: $white;
  border-radius: .4em;
  display: inline-block;
  position: absolute;
  touch-action: manipulation;
  box-shadow: 0px 0px 10px -2px $gray;
  z-index:20;
}

.pickmeup.pmu-flat {
  position: relative
}

.pickmeup.pmu-hidden {
  display: none
}

.pickmeup .pmu-instance {
  display: inline-block;
  //height: 13.8em;
  padding: .5em;
  text-align: center;
  width: 20em;
}

.pickmeup .pmu-instance .pmu-button {
  color: $black-soft;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.pickmeup .pmu-instance .pmu-today {
  background: $wine;
  color: $white;
}
.vi{
  .pickmeup .pmu-instance .pmu-today {
    background: $black-soft;
  }
}

.pickmeup .pmu-instance .pmu-button:hover {
  background: $wine;
  color: $white;
}
.vi .pickmeup .pmu-instance .pmu-button:hover {
  background: $black-soft;
  color: $white;
}

.pickmeup .pmu-instance .pmu-not-in-month {
  color: $gray-light;
}

.pickmeup .pmu-instance .pmu-disabled, .pickmeup .pmu-instance .pmu-disabled:hover {
  color: $black-soft;
  cursor: default
}

.pickmeup .pmu-instance .pmu-selected {
  background: lighten($wine, 30%);
  color: $white;
}
.vi .pickmeup .pmu-instance .pmu-selected {
  background: lighten($black-soft, 30%);
  color: $white;
}

.pickmeup .pmu-instance .pmu-not-in-month.pmu-selected {
  background: lighten($wine, 40%);
  color: $white;
}
.vi .pickmeup .pmu-instance .pmu-not-in-month.pmu-selected {
  background: lighten($black-soft, 40%);
  color: $white;
}

.pickmeup .pmu-instance nav {
  color: $black-soft;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  line-height: 2em;
  justify-content: center;
}

.pickmeup .pmu-instance nav *:first-child :hover {
  color: $black-soft;
}

.pickmeup .pmu-instance nav .pmu-prev, .pickmeup .pmu-instance nav .pmu-next {
  display: none;
  height: 2em;
  width: 1em
}

.pickmeup .pmu-instance nav .pmu-month {
  width: 14em
}

.pickmeup .pmu-instance .pmu-years *, .pickmeup .pmu-instance .pmu-months * {
  display: inline-block;
  line-height: 3.6em;
  width: 3.5em
}

.pickmeup .pmu-instance .pmu-day-of-week {
  color: $black-soft;
  cursor: default;
  font-weight: bold;
  div:nth-child(6),
  div:nth-child(7){
    color: $wine;
  }
}
.vi .pickmeup .pmu-instance .pmu-day-of-week {
  div:nth-child(6),
  div:nth-child(7){
    color: $black-soft;
    background-color: $gray-light;
  }
}

.pickmeup .pmu-instance .pmu-day-of-week *, .pickmeup .pmu-instance .pmu-days * {
  display: inline-block;
  //line-height: 1.5em;
  line-height: 2em;
  //width: 2em;
  width: 14.2857%;
}

.pickmeup .pmu-instance .pmu-day-of-week * {
  line-height: 1.8em
}

.pickmeup .pmu-instance:first-child .pmu-prev, .pickmeup .pmu-instance:last-child .pmu-next {
  display: block
}

.pickmeup .pmu-instance:first-child .pmu-month, .pickmeup .pmu-instance:last-child .pmu-month {
  width: 13em
}

.pickmeup .pmu-instance:first-child:last-child .pmu-month {
  width: 12em
}

.pickmeup:not(.pmu-view-days) .pmu-days, .pickmeup:not(.pmu-view-days) .pmu-day-of-week, .pickmeup:not(.pmu-view-months) .pmu-months, .pickmeup:not(.pmu-view-years) .pmu-years {
  display: none
}

@media (max-width: 400px){
  .pickmeup .pmu-instance {
    width: calc(100vw - 30px);
  }
}