.ajax-file-upload-statusbar {
  border: 1px solid #979797;
  margin-top: 10px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 5px 5px 5px 15px
}

.ajax-file-upload-filename {
  height: auto;
  margin: 0 5px 5px 0px;
}

.ajax-file-upload-filesize {
width: 50px;
height: auto;
margin: 0 5px 5px 0px;
display: inline-block;
vertical-align:middle;
}
.ajax-file-upload-progress {
  margin: 5px 10px 5px 0px;
  position: relative;
  width: 250px;
  border: 1px solid #ddd;
  padding: 1px;
  border-radius: 3px;
  display: inline-block;
  color:#FFFFFF;
}
.ajax-file-upload-bar {
  //background-color: #0ba1b5;
  background-color:$wine;
  width: 0;
  height: 20px;
  border-radius: 3px;
  color:#FFFFFF;
}
.ajax-file-upload-percent {
  position: absolute;
  display: inline-block;
  top: 3px;
  left: 48%
}
.ajax-file-upload-red {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  font-size: 0;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  vertical-align: top;
  margin: 5px 10px 5px 0px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url(../images/icons/cancel.svg);
  background-repeat:no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
}
.ajax-file-upload-green {
  background-color: #77b55a;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  display: inline-block;
  color: #fff;
  font-size: 0;
  font-weight: normal;
  padding: 4px 15px;
  text-decoration: none;
  cursor: pointer;
  vertical-align: top;
  margin: 5px 10px 5px 0px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url(../images/icons/downloading-action.svg);
  background-repeat:no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
}
.ajax-file-upload {
  font-size: 16px;
  padding: 15px 20px;
  cursor:pointer;
  line-height:20px;
  height:25px;
  margin:0 10px 10px 0;
  display: inline-block;
  background: #fff;
  border: 1px solid #e8e8e8;
  color: #888;
  text-decoration: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 6px 0 4px 0;
  color: #fff;
  border: none;
  vertical-align: middle;
  color: #979797;
  }

.ajax-file-upload:hover{

}

.ajax-upload-dragdrop{
  position: relative;
  height: 54px;
  padding: 10px 16px;
  border-radius: 3px;
  border: 1px solid #979797;
  display: block;
  width: 100%;
  &:after{
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 14px;
    top: 11px;
    background-image: url(../images/icons/upload.svg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  input{
    cursor: pointer;
  }
}

.state-hover{
	border:2px solid #A5A5C7;
}
.ajax-file-upload-container{
	margin:20px 0px 20px 0px;
}

// vi
.vi .ajax-file-upload-bar{
  background-color: #353535;
}
.vi .ajax-upload-dragdrop:after{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}