.footer{
  padding-top: 26px;
  background-color: $gray-milk;
  .foreign-students-phone{
    margin-bottom: 6px;
  }
  &__menu-title{
    color: $purple;
    font-size: 18px;
    font-weight:bold;
  }
  .main-tel{
    margin-bottom: 20px;
  }
  &__menu{
    display: flex;
    flex-wrap: wrap;
    li{
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 19px;
      width:50%;
      a{
        font-size: 14px;
        color: $black-soft;
        position: relative;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        &:before{
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          position: absolute;
          top:0;
          left:-20px;
          background-image: url(../images/icons/chevron.svg);
          background-repeat:no-repeat;
          background-position: center center;
        }
        &:hover{
          color: $wine;
        }
      }
      &:nth-child(1){
        order:1;
      }
      &:nth-child(2){
        order:1;
      }
      &:nth-child(3){
        order:1;
      }
      &:nth-child(4){
        order:1;
      }
      &:nth-child(5){
        order:1;
      }
      &:nth-child(6){
        order:1;
      }
      &:nth-child(7){
        order:1;
      }
      &:nth-child(8){
        order:1;
      }
      &:nth-child(9){
        order:1;
      }
      &:nth-child(10){
        order:1;
      }
      &:nth-child(11){
        order:1;
      }
      &:nth-child(12){
        order:1;
      }
    }
  }
  &__menu-download{
    li{
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 4px;
      margin-bottom: 10px;
      a{
        font-size: 14px;
        color: $wine;
        text-decoration: underline;
        position: relative;
        &:before{
          content: "";
          display: block;
          width: 20px;
          height:20px;
          position: absolute;
          top:0;
          left:-26px;
          background-image: url(../images/icons/pdf.svg);
          background-position: center center;
          background-repeat:no-repeat;
        }
      }
    }
  }
  .logo{
    margin-bottom: 34px;
    display: block;
  }
}
.copyright{
  margin-top: 35px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top:1px solid $gray-soft;
}
@media(max-width: 768px){
  .footer{
    display: flex;
    &__menu{
      flex-direction: column;
      li{
        width: 100%;
      }
    }
    &__info-block{
      display: none;
    }
    &__menu-block{
      order: 2;
    }
    &__download-block{
      order: 1;
      margin-bottom: 30px;
    }
  }
}