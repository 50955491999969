.vi{  // body class for visually impaired version of site
  color: $black;
  .header{
    &-inner{
      background-color: $white;
      //position: relative;
    }
    &-bottom{
      height:auto;
      min-height: 40px;
      display: flex;
      align-items: center;
    }
  }
  .logo{
    background-image: url(../images/icons/logo_black.svg);
    img{
      //@include grayscale;
    }
  }

  .main-nav{
    li{
      min-height: 40px;
      padding:12px 0;
      a, span{
        &:hover{
          color: $gray-dark;
        }
      }
      &.active{
        a, span{
          color: $gray-dark;
          &:before{
            content: "";
            display: block;
            width: calc(100% - 40px);
            height: 5px;
            background-color: $gray-dark;
            position: absolute;
            bottom:0;
            left:0;
          }
        }
      }
    }
    .submenu{
      li{
        min-height: 0;
        &.active, &:hover{
          border-left:5px solid $gray-dark;
          background-color:$gray-dark ;
          a{
            color: $white;
          }
        }
      }
      a, span{
        &:before{
          display: none!important;
        }
      }
    }
  }

  .main-tel{
    color: $gray-dark;
  }
  .btn-main{
    background-color: $gray-dark;
    color: $white;
    border:1px solid $gray-dark;
    outline:none;
    &:before{
      display: none;
    }
  }
  .header{
    &__right{
      width:calc(100% - 300px);
      justify-content: space-between;
    }
    .city-phone{
      display: flex;
      //width: calc(100% - 268px);
      flex:1 1;
    }
    .city-choose{
      width:100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__chosen{
        //font-size: 24px;
      }
      span{
        font-size: 16px;
        position: relative;
        margin-top: 10px;
        &:before{
          top: 4px;
        }
        &:after{
          top: 4px;
        }
      }
      &__list{
        right:auto;
         left: 0;
      }
      &__item{
        font-size: 16px;
        &:hover{
          color: $black-soft;
          text-decoration: underline;
        }
      }
      &:hover{
        span{
          color: $black-soft;
        }
      }
    }
  }
}
.vi-panel{
  border-bottom:1px solid $gray-dark;
  &-inner{
    min-height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
  }
}
.fz-set{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fz-change{
  width: 34px;
  height: 34px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 34px;
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
  &.active{
    background-color: $gray-dark;
    color: $white;
  }
  &-title{
    display: inline-block;
    margin-right: 4px;
    font-size: 18px;
  }
}

.site-ver{
  padding-left:44px;
  font-size: 18px;
  display: block;
  position: relative;
  &:before{
    content: "";
    display: block;
    width: 34px;
    height: 24px;
    position: absolute;
    top:50%;
    margin-top: -12px;
    left:0;
    background-image: url(../images/icons/eye.svg);
    background-size: 100%;
    background-position: center center;
  }
}

.fz-change{
  &.fz-big{
    font-weight:400;
  }
  &.fz-bigger{
    font-weight:600;
  }
  &.fz-biggest{
    font-weight:700;
  }
}
@media (max-width: 1199px){
  body.vi{
    .header-top{
      height: auto;
      min-height:78px;
      > .container{
        min-height: 78px;
        > .row{
          min-height: 78px;
        }
      }
      .col-md-12{
        flex-direction: column;
        align-items: flex-start!important;
      }
      .btn{
        overflow: visible;
      }
    }
    .header-inner{
      position: relative;
      .col-lg-12{
        flex-direction: column;
        align-items: flex-start!important;
      }
    }
    .auth{
      margin-top: 10px;
    }
  }
}
@media (max-width: 1023px){
  body.vi{
    .header-inner{
      position: relative;
      .col-lg-12{
        flex-direction: row;
        align-items: center!important;
      }
    }
    .auth{
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 991px){
  body.vi{
    .header{
      .city-choose{
        display: none;
      }
      .city-phone{
        display: none;
      }
    }
    .header{
      &__right{
        width:auto;
        .btn-main{
          background-color:lighten( $gray-light1, 1%);
          border:1px solid lighten( $gray-light1, 1%);
          filter:invert(100%);
        }
      }
    }
    .header-top{
      .col-md-12{
        flex-direction: row;
        align-items: center!important;
      }
    }
  }

}
@media (max-width: 767px){
  .vi-panel{
    &-inner{
      .col-md-6{
        justify-content: flex-start;
        margin: 10px 0;
      }
    }
  }
}
@media (max-width: 767px){
  .vi{
    .auth{
      flex-direction: column;
      margin: 10px 0;
    }
    .btn-buy-policy{
      margin-bottom: 10px;
    }
  }
}
