// ======== normal font size

.fz-big{
  font-size: 16px;
}

// ======== bigger font size ======= //


.fz-bigger{
  font-size: 19px;

  // common //

  h1, .h1like{
    font-size: 45px;
    line-height: 49px;
  }
  h2, .h2like{
    font-size: 39px;
    line-height: 43px;
  }
  h3, .h3like{
    font-size: 23px;
    line-height: 27px;
  }
  h4, .h4like{
    font-size: 19px;
    line-height: 30px;
  }
  .small{
    font-size: 14px;
    line-height: 15px;
  }
  p{
    line-height: 25px;
    margin: 23px 0;
  }
  .feedback-form{
    font-size: 19px;
    .upload-info{
      font-size: 17px;
    }
    .city-choose{
      span{
        font-size: 16px;
        line-height: 19px;
      }
      &__item{
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .question-tooltip{
    font-size: 16px;
    line-height: 19px;
  }
  // header font-size //

  .header{
    .city-choose{
      span{
        font-size: 19px;
        &:before{
          top: 6px;
        }
        &:after{
          top: 6px;
        }
      }
      &__item{
        font-size: 19px;
      }
    }
  }
  .auth__login{
    font-size: 17px;
  }
  .main-tel{
    font-size: 23px;
  }
  .btn{
    font-size: 19px;
  }
  .main-nav{
    li{
      a, span{
        font-size: 17px;
      }
    }
  }
  .logo{
    img{
      width: 230px;
    }
  }
  .site-ver, .fz-change-title{
    font-size: 21px;
  }
  .nav-drill{
    a{
      font-size: 19px;
    }
    #left-menu__inner{
      ul{
        li{
          a{
            font-size: 19px;
          }
        }
      }
    }
  }

  // content font-size //

  .service-inner{
    flex-direction: column;
    text-align: center;
    &__text{
      font-size: 20px;
    }
    &__icon{
      margin-right: 0;
    }
  }
  .evacuation{
    .service-inner{
      &__title{
        font-size: 19px;
      }
      &__text-phone{
        font-size: 15px;
      }
    }
  }

  .slider{
    &__text{
      &--small{
        font-size: 39px;
        line-height: 41px;
      }
    }
  }
  .breadcrumbs{
    a, span{
      font-size: 21px;
    }
  }

  .left-menu{
    li{
      a, span{
        font-size: 18px;
      }
    }
  }
  .content-404{
    &__title{
      font-size: 39px;
    }
    &__text{
      font-size: 28px;
    }
    &__notice{
      font-size: 19px;
    }
  }

  .services.half-services{
    .service-inner{
      &__text{
        font-size: 23px;
        line-height: 26px;
        div{
          font-size:20px;
          line-height: 23px;
        }
      }
    }
  }
  .contacts{
    &-header{
      &-item{
        font-size: 21px;
      }
    }
    &-item{
      &__infoblock{
        div{
          font-size: 19px;
        }
      }
    }
  }
  .balloon{
    &-title{
      font-size: 15px;
    }
    &-address{
      font-size: 15px;
    }
    &-tel{
      font-size: 15px;
    }
  }
  .services-header-item{
    font-size: 25px;
  }
  .content-form{
    .city-choose{
      span{
        font-size: 19px;
      }
    }
    .city-choose__item{
      font-size: 15px;
      white-space: nowrap;
    }
  }
  .phone-block{
    &__title{
      font-size: 23px;
    }
    &__text{
      font-size: 19px;
    }
  }
  .figured-title{
    font-size: 31px;
  }
  .qa-block__header{
    font-size: 32px;
  }
  .review-block{
    &__title{
      font-size: 27px;
      line-height: 32px;
    }
    &__date{
      font-size: 20px;
      line-height: 24px;
    }
    &__text{
      font-size: 20px;
      line-height: 24px;
    }
  }
  .search-block-content{
    &__title{
      font-size: 20px;
      line-height: 24px;
    }
    &__text{
      font-size: 20px;
      line-height: 24px;
    }
    &__path{
      font-size: 20px;
      line-height: 24px;
    }
  }
  .content{
    .order-tabs{
      li{
        font-size: 17px;
      }
    }
  }

  // footer font-size //

  .footer{
    &__menu-title{
      font-size: 21px;
    }
    &__menu{
      li{
        a{
          font-size: 17px;
        }
      }
    }
    &__menu-download{
      li{
        a{
          font-size: 17px;
        }
      }
    }
  }
}


// ======== biggest font size ========= //

.fz-biggest{
  font-size: 22px;

  // common //

  h1, .h1like{
    font-size: 48px;
    line-height: 52px;
  }
  h2, .h2like{
    font-size: 42px;
    line-height: 45px;
  }
  h3, .h3like{
    font-size: 26px;
    line-height: 30px;
  }
  h4, .h4like{
    font-size: 22px;
    line-height: 33px;
  }
  .small{
    font-size: 17px;
    line-height: 18px;
  }
  p{
    line-height: 28px;
    margin: 26px 0;
  }
  .feedback-form{
    font-size: 22px;
    .upload-info{
      font-size: 20px;
    }
    .city-choose{
      span{
        font-size: 19px;
        line-height: 22px;
      }
      &__item{
        font-size: 19px;
        line-height: 22px;
      }
    }
  }
  .question-tooltip{
    font-size: 19px;
    line-height: 22px;
  }

  // header font-size //

  .header{
    .city-choose{
      span{
        font-size: 22px;
        &:before{
          top: 8px;
        }
        &:after{
          top: 8px;
        }
      }
      &__item{
        font-size: 22px;
      }
    }
  }
  .auth__login{
    font-size: 20px;
  }
  .main-tel{
    font-size: 26px;
  }
  .btn{
    font-size: 22px;
  }
  .main-nav{
    li{
      a, span{
        font-size: 20px;
      }
    }
  }
  .logo{
    img{
      width: 240px;
    }
  }
  .site-ver, .fz-change-title{
    font-size: 24px;
  }
  .nav-drill{
    a{
      font-size: 22px;
    }
    #left-menu__inner{
      ul{
        li{
          a{
            font-size: 22px;
          }
        }
      }
    }
  }

  // content font-size //

  .service-inner{
    flex-direction: column;
    text-align: center;
    &__text{
      font-size: 23px;
    }
    &__icon{
      margin-right: 0;
    }
  }

  .evacuation{
    .service-inner{
      &__title{
        font-size: 22px;
      }
      &__text-phone{
        font-size: 18px;
      }
    }
  }

  .slider{
    &__text{
      &--small{
        font-size: 42px;
        line-height: 44px;
      }
    }
  }
  .breadcrumbs{
    a, span{
      font-size: 24px;
    }
  }
  .left-menu{
    li{
      a, span{
        font-size: 21px;
      }
    }
  }
  .content-404{
    &__title{
      font-size: 42px;
    }
    &__text{
      font-size: 31px;
    }
    &__notice{
      font-size: 22px;
    }
  }

  .services.half-services{
    .service-inner{
      &__text{
        font-size: 26px;
        div{
          font-size:23px;
        }
      }
    }
  }
  .contacts{
    &-header{
      &-item{
        font-size: 24px;
      }
    }
    &-item{
      &__infoblock{
        div{
          font-size: 22px;
        }
      }
    }
  }
  .balloon{
    &-title{
      font-size: 18px;
    }
    &-address{
      font-size: 18px;
    }
    &-tel{
      font-size: 18px;
    }
  }
  .services-header-item{
    font-size: 28px;
  }
  .content-form{
    .city-choose{
      span{
        font-size: 22px;
      }
    }
    .city-choose__item{
      font-size: 18px;
      white-space: nowrap;
    }
  }
  .figured-title{
    font-size: 34px;
  }
  .phone-block{
    &__title{
      font-size: 26px;
    }
    &__text{
      font-size: 22px;
    }
  }
  .qa-block__header{
    font-size: 35px;
  }
  .review-block{
    &__title{
      font-size: 30px;
      line-height: 35px;
    }
    &__date{
      font-size: 23px;
      line-height: 27px;
    }
    &__text{
      font-size: 23px;
      line-height: 27px;
    }
  }
  .search-block-content{
    &__title{
      font-size: 23px;
      line-height: 27px;
    }
    &__text{
      font-size: 23px;
      line-height: 27px;
    }
    &__path{
      font-size: 23px;
      line-height: 27px;
    }
  }

  .content{
    .order-tabs{
      li{
        font-size: 20px;
      }
    }
  }
  // footer font-size //

  .footer{
    &__menu-title{
      font-size: 24px;
    }
    &__menu{
      li{
        a{
          font-size: 20px;
        }
      }
    }
    &__menu-download{
      li{
        a{
          font-size: 20px;
        }
      }
    }
  }

}

@media (max-width: 991px){
  .fz-bigger{
    .header{
      .btn{
        &.feedback-btn{
          font-size: 0;
        }
      }
    }
  }
  .fz-biggest{
    .header{
      .btn{
        &.feedback-btn{
          font-size: 0;
        }
      }
    }
  }
}
@media (max-width: 767px){
  .fz-bigger{
    .table *{
      font-size: 17px;
    }
  }
  .fz-biggest{
    .table *{
      font-size: 20px;
    }
  }
}