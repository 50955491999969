label{
  display: block;
  margin-bottom: 20px;
}
.feedback-form{
  overflow-y: auto;
  z-index:100;
  position: absolute;
  display: none;
  top:70px;
  left:50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background: $white;
  padding: 25px;
  max-width:540px;
  width:100%;
  font-size: 16px;
  input[type='text'],
   input[type='number'],
   input[type='tel'],
   input[type='email'],
   select,
   textarea,
   .ui-selectmenu-button{
     margin-top: 10px;
   }
  textarea{
    width: 100%;
    height: 130px;
  }
  &__title{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  &__close{
    z-index:10;
    position: absolute;
    cursor: pointer;
    top:25px;
    right:25px;
    width: 14px;
    height: 14px;
    background-image: url(../images/icons/close.svg);
    background-repeat:no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .btn-main{
    //position: absolute;
    //right: 25px;

  }
  .intl-tel-input{
    margin-top: 10px;
  }
  .tabs{
    margin: 20px 0 0 0;
    &__content{
      flex-direction: column;
    }
    .services-header{
      padding-left: 0;
      padding-right: 0;
    }
    .services-header-item{
      font-size: 1.2em;
      padding:10px;
    }
  }
  &__bottom{
    align-items: flex-start;
    label.checkbox{
      flex:2 1;
    }
    .btn{
      flex:1 1;
      text-align: center;
    }
  }
}
.feedback-form{
  .city-phone{
    display: block;
  }
  .foreign-students-phone{
    display: block;
  }
}

.content-form{
  .city-choose{
    &__list{
      right:auto;
    }
    span{
      font-size: 16px;
      margin-left: 30px;
      &:before{
        display: none;
      }
    }
  }
}
.content-feedback-form{
  &__city-choose{
    display: flex;
  }
}
.question-tooltip{
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
  color: $white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: $wine;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  &__text{
    display: block;
    position: absolute;
    left:120%;
    top:50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width:300px;
    padding: 10px 16px;
    text-align: left;
    color: $gray-dark;
    background-color:$gray-light-extra;
    z-index:2;
    &:before{
        content: "";
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-right: 6px solid $gray-light-extra;
        border-bottom: 6px solid transparent;
        position: absolute;
        top: 50%;
        left:-6px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        display: block;
    }
  }
}
/* content form */

.content-form{
  margin-top: 30px;
  width: 50%;
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"]
  input[type="tel"]
  textarea{
    width: 100%;
  }
}
.calculator-field-loader{
  display: none;
}
.button-preloader{
  display: none;
}
.remark{
  display: none;
}
.calculator-field{
  margin-bottom: 16px;
}
.calculator-field-sub-group-join{
  margin-bottom: 16px;
}
.calculator-field-sub-group.age{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.label{
  margin: 20px 0;
}
.half-width{
  width: 48%;
}
.summ{
  color: $wine;
}
.add-button{
  color: $wine;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  display: inline-block;
  &:before{
    content: "+";
    display: block;
    width: 16px;
    height: 16px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: $wine;
    color: $white;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.label-half-width{
  display: inline-block;
  margin-right: 12px;
  vertical-align: top;
  width: 50%;
  & + .label-half-width{
    margin-right: 0;
    width: 45%;
  }
}
.content-textarea{
  height: 140px;
}

.territory__reset{
  margin: 10px 0;
  &-item{
    margin-right: 10px;
    color: $gray-soft1;
    border-bottom:1px dashed $gray-soft1;
    display: inline-block;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  &-reset{
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    cursor: pointer;
    &:before{
      content: "";
      display: block;
      width: 12px;
      height: 2px;
      position: absolute;
      top:4px;
      left:0;
      transform: rotate(45deg);
      background-color: $gray-soft1;
    }
    &:after{
      content: "";
      display: block;
      width: 12px;
      height: 2px;
      position: absolute;
      top:4px;
      left:0;
      transform: rotate(-45deg);
      background-color: $gray-soft1;
    }
    &:hover{
      &:before{
        background-color: $wine;
      }
      &:after{
        background-color: $wine;
      }
    }
  }
}

/* trip form */

.trip-form{
  label:not(#for-sport):not(#for-agreement3){
    max-width:50%;
  }
  #for-agreement3{
    padding-right: 20px;
  }
  .sport-input{
    width: 160px;
    margin-right: 48px;
  }
  .change-calculation{
    color: $gray-soft1;
    border-bottom:1px dashed $gray-soft1;
  }
  .passport-serial{
    width: 104px;
  }
  .passport-number{
    width: 246px;
  }
  .label-half-width{
    width: 45%;
    margin-right: 12px;
  }
  .label-half-width+ .label-half-width{
    width: 45%;
    margin-right: 12px;
  }
}
.types-wr{
  width:100%;
}
.trip-dates{
  width:100%;
}
.members{
  width:100%;
}
.traveler{
  h3{
    display: block;
    width: auto;
  }
}
.trip-dates{
  display: flex;
  align-items: center;
  label{
    display: flex;
    align-items: center;
  }
}
#trip-date-begin{
  width: 160px;
  margin: 0 20px;
}
#trip-date-end{
  width: 160px;
  margin: 0 20px;
}
#trip-days-amount{
  width: 72px;
  margin: 0 20px;
}
.member{
  display: flex;
  align-items: center;
  position: relative;
}
.checkbox.sport-all{
  margin-left: -20px;
  position: relative;
  z-index:5;
  background-color: $white;
  .checkmark{
    background-color: $white;
    border:2px solid $gray-dark;
    &:before{
      top: 2px;
      left: 1px;
      border-left: 2px solid $gray-dark;
      border-bottom: 2px solid $gray-dark;
    }
  }
}
.sport-search{
  margin-right: 14px;
}
.member-delete{
  position: relative;
  width: 16px;
  height: 16px;
  margin-bottom: 20px;
  margin-left: 4px;
  cursor: pointer;
  &:before{
    content: "";
    display: block;
    width: 18px;
    height: 2px;
    background: $wine;
    transform: rotate(45deg);
    position: absolute;
    top:7px;
    left:-1px;

  }
  &:after{
    content: "";
    display: block;
    width: 18px;
    height: 2px;
    background: $wine;
    transform: rotate(-45deg);
    position: absolute;
    top:7px;
    left:-1px;
  }
}
.new-order-result{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4{
    width: auto;
    margin-right: 30px;
  }
  &__under{
    width:100%;
    align-items: flex-start;
  }
}

/* media */
@media(max-width: 1199px){
  .content-form{
    width: 100%;
    .btn{
      margin-right: 20px;
    }
  }
}

@media(max-width: 991px){
  .feedback-form{
    &__title{
      text-align: center;
    }
    .city-phone{
      margin-right: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
    .foreign-students-phone{
      margin-right: 0;
      text-align: center;
    }
    .city-choose{
      &__list{
        left: 0;
      }
    }
    .main-tel{
      margin-top: 10px;
    }
  }
}
@media(max-width: 991px){
  .feedback-form{
    top:0;
  }
}
@media(max-width: 540px){
  .feedback-form{
    overflow: scroll;
  }
  .content-form{
    .btn{
      margin-right: auto;
      margin-left: auto;
      display: block;
      //max-width: 220px;
      max-width: 100%;
      text-align: center;
    }
  }
  .question-tooltip{
    &__text{
      top:120%;
      left:50%;
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      -o-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      &:before{
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid $gray-light-extra;
          top: -12px;
          left: 50%;
          -webkit-transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
          transform: translate(-50%, 0);

      }
    }
  }
}
@media(max-width: 460px){
  .calculator-field-sub-group.age{
    display: block;
  }
  .half-width{
    width: 100%;
  }

}