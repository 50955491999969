.vi{
  .index-slider{
    height: 262px;
    .slider__info{
      top: auto;
      bottom:20px;
    }
  }
  .slider{
    &-small{
      background-color: $white;
      overflow: visible;
      height:auto;
      margin-bottom: 10px;
      padding-bottom: 20px;
      padding-top: 200px;
      .slider__info{
        position: relative;
        margin: 0 auto;
        top:auto;
        left:0;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
      }
    }
    &__text--small{
      color: $gray;
      text-shadow: none;
    }
  }
  .breadcrumbs{
    a{
      color: $gray;
      text-shadow: none;
    }
    &__separator{
      color: $gray;
      text-shadow: none;
    }
    span{
      color: $gray;
      text-shadow: none;
    }
  }

}
@media (max-width: 1199px){
  .vi{
    .index-slider{
      height: 20px;
    }
  }
}
@media (max-width: 991px){
  .breadcrumbs{
    display: none;
  }
}
@media (max-width: 767px){
  .vi{
    .slider{
      &-small{
        padding-top: 230px;
      }
    }
  }
}

