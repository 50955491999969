.index-slider {
    width:100%;
    height:675px;
    overflow: hidden;
    position: relative;
    img{
        //width:auto!important;
        max-width:none;
        position: relative;
        z-index:5;
    }
  .owl-dots {
    position: absolute;
    top: 50%;
    z-index:3;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    .owl-dot{
      margin: 6px 0;
      border:2px solid $blue!important;
      width: 16px;
      height: 16px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      position: relative;
      &.active{
        border:2px solid $wine!important;
        span{
          position: absolute;
          top:50%;
          left:50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 7px;
          height: 7px;
          background-color: $wine;
          display: inline-block;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }
  }
  .owl-item.active{
    z-index:5;
  }
}
.slider{
  max-width:1920px;
  margin-left: auto;
  margin-right: auto;
  &-big{
    height:687px;
    width:100%;
    position: relative;
    background-color: $gray-light1;
    &__desktop{
      display: block;
      width: 1920px;
      height:687px;
      object-fit: cover;
    }
    &__tablet{
      display: none!important;
      width: 770px;
      height:687px;
      object-fit: cover;
    }
    &__mobile{
      display: none!important;
      width: 480px;
      //height: 480px;
      height: 100%;
      object-fit: cover;
    }
  }
  &__info{
    position: absolute;
    top:260px;
    left:50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index:11;
    .btn-second{
      margin-right: 20px;
    }
  }
  &__text{
    font-size: 50px;
    line-height:50px;
    color: $white;
    font-family:Georgia, 'Times New Roman', serif;
    font-style: italic;
    margin-bottom: 40px;
    text-shadow: 2px 2px 8px $black-soft;
  }
  &__text--small{
    font-size: 36px;
    line-height: 36px;
    color: $white;
    font-family:Georgia, 'Times New Roman', serif;
    font-style: italic;
    text-shadow: 2px 2px 8px $black-soft;
  }
  &-small{
    height:318px;
    width:100%;
    overflow: hidden;
    margin-bottom: 40px;
    position: relative;
    background-color: $gray-light1;
    &__desktop{
      display: block;
      width: 1920px;
      height: 340px;
      object-fit: cover;
    }
    &__tablet{
      display: none;
      width: 770px;
      height: 301px;
      object-fit: cover;
    }
    &__mobile{
      display: none;
      width: 480px;
      //height: 480px;
      height: 100%;
      object-fit: cover;
    }
    img{
      //margin-top: -80px;
      max-width:none;
      position: absolute;
      left:50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .slider__info{
      //top: 180px;
      top: 64%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%), -50%;
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    &.slider-small-car{
      img{
        margin-top: 0;
      }
    }
  }
}

// prop
.zoomInSlow{
    animation-name: zoomInSlow;
    -webkit-animation-name: zoomInSlow;
    animation-duration: 5s;
    -webkit-animation-duration: 5s;
   animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
// keys
@keyframes zoomInSlow {
    0% {
        -webkit-transform: scale3d(1.0, 1.0, 1.0);
        transform: scale3d(1.0, 1.0, 1.0);
      animation-timing-function: ease-in-out;
      -webkit-animation-timing-function: ease-in-out;
    }
    80% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
      animation-timing-function: ease-in;
      -webkit-animation-timing-function: ease-in;
    }
  //100%{
  //  -webkit-transform: scale3d(1.0, 1.0, 1.0);
  //  transform: scale3d(1.0, 1.0, 1.0);
  //  animation-timing-function: ease-out;
  //  -webkit-animation-timing-function: ease-out;
  //}
}

.breadcrumbs{
  margin-top: 20px;
  span{
    color: $white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.69);
    font-size: 18px;
  }
  a{
    color: $white;
    text-decoration: underline;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.69);
    font-size: 18px;
  }
  &__separator{
    color: $white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.69);
    font-size: 18px;
  }
}
@media (min-width: 576px) {
  .index-slider{
    .owl-dots {
      max-width: 540px;
    }
  }
}

@media (min-width: 768px) {
  .index-slider{
    .owl-dots {
      max-width: 720px;
    }
  }
}

@media (min-width: 992px) {
  .index-slider{
    .owl-dots {
      max-width: 960px;
    }
  }
}

@media (min-width: 1200px) {
  .index-slider{
    .owl-dots {
      max-width: 1140px;
    }
  }
}

/* ---- */
@media(max-width: 1366px){
  .slider{
    &-small{
      img{
        margin-top: 0;
      }
    }
  }
}
@media(max-width: 1199px){
  .slider{
    &-small{
      img{
        //margin-top: ;
        max-width:120%;
      }
    }
  }
}
@media (max-width: 1023px){

}
@media(max-width: 991px){
  .slider{
    &-small {
      margin-bottom: 20px;
    }
  }
  .index-slider{
    height: 450px;
  }
  .slider__info{
    top: 180px;
  }
  .slider{
    &-big{
      &__desktop{
        display: none!important;
      }
      &__tablet{
        display: block!important;
        height: 450px;
      }
      &__mobile{
        display: none!important;
      }
    }
  }
}

@media (max-width: 900px) {
  .slider{
    &-small{
      //height: 270px;
      height: 300px;
      //img{
      //  max-width:none;
      //  height:100%;
      //  position: absolute;
      //  left:50%;
      //  -webkit-transform: translateX(-50%);
      //  -moz-transform: translateX(-50%);
      //  -ms-transform: translateX(-50%);
      //  -o-transform: translateX(-50%);
      //  transform: translateX(-50%);
      //}
    }
  }
}
@media(max-width: 800px){
  .breadcrumbs{
    display: none;
  }
}
@media (max-width:767px) {
  .index-slider{
    height: 450px;
    .owl-dots{
      flex-direction: row;
      align-items: center;
      top:auto;
      bottom:10px;
      justify-content: center;

      .owl-dot{
        margin:0 4px;
      }
    }
  }
  .slider{
    &__info{
      top: 180px;
    }
    .btn-second{
      display: none;
    }
  }
  .slider{
    &-small{
      &__desktop{
        display: none;
      }
      &__tablet{
        display: block;
      }
      &__mobile{
        display: none;
      }
    }
  }
}
@media(max-width: 500px){

}
@media(max-width: 480px){
  .slider{
    &-small{
      height: 334px;
      &__desktop{
        display: none;
      }
      &__tablet{
        display: none;
      }
      &__mobile{
        display: block;
      }
    }
    &-big{
      &__desktop{
        display: none!important;
      }
      &__tablet{
        display: none!important;
      }
      &__mobile{
        display: block!important;
        height: 450px;
      }
    }
  }
}


/* slider img */
@media(max-width: 1600px){
  .index-slider{
    img{
      //margin-left: -180px;
    }
  }
}
@media(max-width: 1366px){
  .index-slider{
    img{
      //margin-left: -300px;
    }
  }
}
@media(max-width: 1199px){
  .index-slider{
    img{
      //margin-left: -450px;
    }
  }
}
@media(max-width: 1023px){
  .index-slider{
    img{
      //margin-left: 0px;
      //position: absolute;
      //right:-270px;
      //top:0;
    }
  }
}
@media(max-width: 991px){
  .index-slider{
    img{
      //right: -250px;
      //height: 500px;
    }
  }
}