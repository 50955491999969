/* PROXIMA NOVA */
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Light.eot');
  src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Light.woff') format('woff'),
  url('../fonts/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-LightIt.eot');
  src: url('../fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-LightIt.woff') format('woff'),
  url('../fonts/ProximaNova-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Regular.eot');
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-RegularIt.eot');
  src: url('../fonts/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-RegularIt.woff') format('woff'),
  url('../fonts/ProximaNova-RegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Semibold.eot');
  src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-SemiboldIt.eot');
  src: url('../fonts/ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-SemiboldIt.woff') format('woff'),
  url('../fonts/ProximaNova-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Bold.eot');
  src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-BoldIt.eot');
  src: url('../fonts/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-BoldIt.woff') format('woff'),
  url('../fonts/ProximaNova-BoldIt.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
/* GEORGIA */
@font-face {
  font-family: 'Georgia';
  src: url('../fonts/Georgia.eot');
  src: url('../fonts/Georgia.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Georgia.woff') format('woff'),
  url('../fonts/Georgia.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Georgia';
  src: url('../fonts/Georgia-Italic.eot');
  src: url('../fonts/Georgia-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Georgia-Italic.woff') format('woff'),
  url('../fonts/Georgia-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Georgia';
  src: url('../fonts/Georgia-Bold.eot');
  src: url('../fonts/Georgia-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Georgia-Bold.woff') format('woff'),
  url('../fonts/Georgia-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Georgia';
  src: url('../fonts/Georgia-BoldItalic.eot');
  src: url('../fonts/Georgia-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Georgia-BoldItalic.woff') format('woff'),
  url('../fonts/Georgia-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@import "_normalize";
@import "_colors";
@import "_bootstrap-grid";
@import "_balloon";
//@import "_slick";
//@import "_slick-theme";
@import "_jquery-ui";
@import "_pickmeup";
@import "_loading-overlay";
@import "_jquery-fancybox";
@import "_owl.carousel";
@import "_owl.theme.default";
@import "_animate";
@import "_header";
@import "_slider";
@import "_left-menu";
@import "_search-form";
@import "_form";
@import "_table";
@import "_intlTelInput";
@import "_uploadfile";
@import "_menu-mobile";
@import "_content";
@import "_content-page";
@import "_search";
@import "_footer";
@import "_vi-header";
@import "_vi-slider";
@import "_vi-form";
@import "_vi-content";
@import "_vi-footer";
@import "_vi-styles-ie";
@import "_vi-font-size";

::-webkit-input-placeholder {

}
::-moz-placeholder  {

}
:-moz-placeholder{

}
::selection {
  background: $wine;
  color: $white;
}
::-moz-selection {
  background: $wine;
  color: $white;
}
.vi{
  ::selection {
    background: $gray-dark;
  }
  ::-moz-selection {
    background: $gray-dark;
  }
}
.seacrh__text-input:-ms-input-placeholder{

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
ul{
  li{
    list-style-type:none;
  }
}

//ol{
//  counter-reset: item;
//  li{
//    &:before {
//      content: counter(item) " ";
//      counter-increment: item;
//    }
//  }
//}

html, body{
  height:100%;
}
body{
  min-width:320px;
  font-size: 16px;
  font-family:ProximaNova, sans-serif;
  font-weight:normal;
  font-style: normal;
}
.display-none{
  display: none!important;
}
h1, .h1like{
  font-size: 42px;
  line-height: 46px;
  font-weight:bold;
  margin: 34px 0 20px;
  width: 100%;
}
h2, .h2like{
  font-size: 36px;
  line-height: 40px;
  font-weight:bold;
  margin: 20px 0 20px;
  width: 100%;
}
h3, .h3like{
  font-size: 20px;
  line-height: 24px;
  font-weight:bold;
  margin: 20px 0 20px;
  width: 100%;
}
h4, .h4like{
  font-weight:bold;
  margin: 20px 0 20px;
  width: 100%;
}
h5, .h5like{

}
.no-border{
  border: none!important;
}
a{
  text-decoration: none;
  color:$wine-dark;
}
.vi{
  a{
    color: $gray-dark;
  }
}
p{
  margin: 20px 0;
  line-height:22px;
  text-align: justify;
}
.small{
  font-size: 11px;
  line-height: 12px;
  color: $black-soft;
}
img{
  display: block;
  max-width:100%;
}
.f-l{
  float: left;
}
.f-r{
  float: right;
}
.clear{
  clear: both;
  position: relative;
}
.wrapper{
  &.sticky {
    padding-top: 100px;
  }
}
.text-align-center{
  text-align: center;
}
.btn{
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 13px 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  &:before{
    content: "";
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    top:0;
    left:-20px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: skew(-25deg);
    -moz-transform: skew(-25deg);
    -ms-transform: skew(-25deg);
    -o-transform: skew(-25deg);
    transform: skew(-25deg);
    opacity:0;
  }
  &:hover{
    &:before{
      width:140%;
      opacity:1;
    }
  }
}
.btn-main{
  background-color: $wine;
  color: $white;
  border:1px solid $wine;
  &:before{
    background-color: $purple;
    border:1px solid $purple;
    height:110%;
  }
  &:hover{
    border:1px solid $purple;
  }
  span{
    position: relative;
    z-index:1;
    color: $white;
  }
}
.btn-second{
  color: $blue;
  background: $gray-transp;
  border:1px solid $blue;
  &:before{
    background-color: $gray-transp-hover;
    height:110%;
  }
  span{
    position: relative;
    z-index:1;
    color: $blue;
  }
}
.inline-block{
  display: inline-block;
}
input,
option,
button,
textarea{
  &:hover{
    outline:none;
  }
  &:focus{
    outline:none;
  }
  &:active{
    outline:none;
  }
}
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
label.upload-file,
textarea{
  height:54px;
  padding: 10px 16px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border:1px solid $gray-soft;
  display: block;
  width:100%;
  &:focus{
    border:1px solid $gray;
  }
}
textarea{
  resize:vertical;
}
input[type="checkbox"]{
  display: none;
}
.checkbox{
   padding-left: 30px;
   position: relative;
   display: inline-block;
   cursor: pointer;
   .checkmark{
     position: absolute;
     display: block;
     width: 16px;
     height: 16px;
     top: 4px;
     left: 4px;
     background: $wine;
     -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
     border-radius: 2px;
     &:before{
       content: "";
       display: none;
       width: 10px;
       height: 6px;
       position: absolute;
       top: 3px;
       left: 3px;
       border-left:2px solid $white;
       border-bottom:2px solid $white;
       -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
       -ms-transform: rotate(-45deg);
       -o-transform: rotate(-45deg);
       transform: rotate(-45deg);
     }
   }
   input[type="checkbox"]:checked + .checkmark:before{
     display: block;
   }
 }
.vi{
  .checkbox{
    .checkmark{
      background-color: $gray-dark;
    }
  }
}
input[type="radio"]{
  display: none;
}
.radio{
  padding-left: 30px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  .checkmark{
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
    border:2px solid $wine;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    &:before{
      content: "";
      display: none;
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: $wine;
    }
  }
  input[type="radio"]:checked + .checkmark:before{
    display: block;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.vi{
  .radio{
    .checkmark{
      border:2px solid $gray-dark;
      &:before{
        background-color: $gray-dark;
      }
    }
  }
}

.mt-0{
  margin-top:0;
}
.mb-0{
  margin-bottom:0;
}
.mt-20{
  margin-top:20px;
}
hr{
  height:1px;
  background-color: $gray-soft;
  opacity:0.34;
  border:none;
  outline:none;
  margin-top: 24px;
  margin-bottom: 14px;
}
.file-block{
  margin: 20px 0;
}
.doc{
  background-image: url(../images/icons/doc.svg);
  display: inline-block;
  width: 15px;
  height: 17px;
  font-size: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  &:hover{
    opacity:0.65;
  }
}
.hide {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload-info{
  margin-top: -20px;
  font-size: 14px;
}

label.upload-file {
  cursor:pointer;
  display: flex;
  align-items: center;
  position: relative;
  span{
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    color: $gray-soft;
    padding-right: 40px;
  }
  &:after{
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 14px;
    top: 11px;
    background-image: url(../images/icons/upload.svg);
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat:no-repeat;
  }
}

.required{
  display: inline-block;
  margin-bottom: 4px;
  &:after{
    content: " *";
    display: inline-block;
    margin-left: 2px;
    color: $wine;
  }
}
.vi{
  .required{
    &:after{
      color: $black-soft;
    }
  }
}
.not-required{
  display: inline-block;
  margin-bottom: 4px;
}
.error{
  color: $wine;
  margin-bottom: 14px;
  display: none;
}
.redtext{
  color: $wine;
}
.vi{
  .redtext{
    color: $gray-dark;
    font-weight: bold;
  }
}

.gray-layout{
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.6);
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media (max-width: 768px) {
  h1, .h1like{

  }
  h2, .h2like{

  }
  h3, .h3like{

  }
  h4, .h4like{

  }
  .slider{
    &__text{
      font-size: 32px;
      line-height: 32px;
    }
  }
}
@media (max-width: 500px) {
  .slider{
    &__text{
      text-align: center;
    }
  }
  .slider__info{
    display: flex;
    align-items: center;
    flex-direction: column;
    .btn-second{
      margin-right:0;
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 480px){

}
@media (max-width: 400px) {

}
/* TEMP */
//.container{
//  outline:1px solid black;
//}
//body{
//  background: green;
//}
/* / TEMP */
