.vi{
  .footer{
    background-color: $black-soft;
    &__menu-download{
      li{
        a{
          &:before{
            @include invert_to_white;
          }
        }
      }
    }
    .logo{
      background-image: url(../images/icons/logo_white.svg);
      img{
        //@include invert_to_white;
      }
    }
    a{
      color: $white;
      &:hover{
        color: $white;
        text-decoration: underline;
      }
    }
    &__menu-title{
      color: $white;
    }
  }
  .copyright{
    color: $white;
  }
}
