.header{
  &__search-opener{
    margin-right: 30px;
    cursor: pointer;
  }
  &__icon-search{
    background-image: url("../images/icons/search.svg");
    background-repeat: no-repeat;
    width:22px;
    height:22px;
    cursor: pointer;
    font-size: 0;
    background-color: transparent;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    &.header__search-opener{
      width: 24px;
      height: 24px;
    }
  }
  &__icon-search-top{
    display: none;
  }
  &__search-btn{
    position: absolute;
    top:50%;
    right:68px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity:0;
  }
  &__close{
    opacity:0;
    position: absolute;
    top:50%;
    right:15px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    background-image: url("../images/icons/close.svg");
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    cursor: pointer;
    font-size: 0;
    background-color: transparent;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
  }
}
.search-block{
  right:200px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
  z-index:10;
  position: absolute;
  top: 50%;
  width: 0%;
  background: rgba(255,255,255,1);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 100%;
  -webkit-box-shadow: 0 0 10px 0 $gray;
  -moz-box-shadow: 0 0 10px 0 $gray;
  box-shadow: 0 0 10px 0 $gray;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  p{
    opacity:0;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 48px;
    height: 40%;
    border-right: 1px solid $blue;
  }
  input[type="text"]{
    border-bottom:1px solid $gray-light;
    margin-left: 15px;
    height:70%;
    width:calc(100% - 130px);
    color: $gray;
    padding: 0;
  }
  input[type="submit"],
  button,
  button[type="submit"]{

  }
  &.opened-search{
    width:calc(100% - 215px);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
}
.search-inner{
  height:100%;
}
.search_bar{
  height:100%;
  display: flex;
  align-items: center;
  input[type="text"]{
    border: none!important;
  }
}
@media(max-width: 1024px){
  .search-block{
    height:99%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
  }
}
@media(max-width: 991px){
  .header{
    &__search-opener{
      display: none;
    }
    &__icon-search-top{
      display: block;
    }
  }
}
@media(max-width:767px){
  .search-block{
    right:0;
    left:0;
    &.opened-search{
      width:100%;
    }
  }
}
@media (max-width: 420px){
  .header{
    &__search-opener{
      margin-right: 10px;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header__search-opener{
    margin-right: 0;
  }
}