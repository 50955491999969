// common
$black: #000;
$black-soft: #2d2d2d;
$white-transparent: rgba(255,255,255,0.5);
$milky: #f5f5f5;
$gray-milk: #f0f0f0;
$white: #fff;

// gray
$gray-dark: #3d3d3d;
$gray: #4d4d4d;

$gray-soft1: #848484;
$gray-soft: #979797;
$gray-light: #d8d7d7;
$gray-light1: #bfbfbf;
$gray-light-extra: #ececec;
$gray-transp: rgba(209, 211, 212, 0.61);
$gray-transp-hover: rgba(79, 90, 124, 0.2);
$gray-transparent: rgba( $gray, .5 );

// green
$green: #38aa1e;

// red
$red: #ff0000;

// yellow
$orange: #f8d207;

// blue
$blue: #4f5a7c;

// purple
$purple: #812944;
$wine: #c82d4e;
$wine-dark: #b7181a;
$wine-deep: #7a1819;

@mixin grayscale{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@mixin invert_to_white{
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}


