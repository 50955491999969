.vi{
  .service-inner{
    border:1px solid $black-soft;
    &__icon{
      //@include grayscale;
      filter: grayscale(100%) invert(100%);
      //-webkit-filter: grayscale(100%);
      //-moz-filter: grayscale(100%);
      //-ms-filter: grayscale(100%);
      //-o-filter: grayscale(100%);
      //filter: grayscale(100%);
      //filter: grayscale(1);
      //filter: gray;

      &.warning{
        background-color: $black-soft;
        filter: grayscale(100%);
      }
    }
    &:hover{
      &:before, &:after{
        display: none;
      }
      .service-inner__text{
        color: $black-soft;
      }
    }
  }
  .service{

    &:last-child{
      .service-inner{
        border:1px solid $black-soft;
        &__icon.warning{
          filter: grayscale(100%); 
          background-color: $black-soft;
        }
      }
    }
  }
  .services.half-services{
    .service-inner__text{
      div{
        color: $black-soft;
      }
    }
    .service:last-child{
      .service-inner{
        border:1px solid $black-soft;
      }
    }
  }

  .services-header-item{

    &.active{
      color: $gray-dark;
      &:before{
        background-color: $gray-dark;
        height: 5px;
      }
    }
    &:hover{
      color: $gray-dark;
      &:before{
        background-color: $gray-dark;
        height: 5px;
      }
    }
    &:after{
      background-color: $gray-dark;
      height: 5px;
    }
    &:before{
      height: 5px;
    }
  }

  .btn-second{
    background-color: $white;
    color: $gray-dark;
  }

  .tabs{
    margin-top: 0;
  }
  .content ul:not(.country-list):not(.tabs__caption) li:before{
    @include grayscale;
  }
  .content{
    .order-tabs{
      li{
        width: 20%;
        text-align: center;
      }
    }
  }
  .member-delete{
    &:before{
      background:$gray-dark;
    }
    &:after{
      background:$gray-dark;
    }
  }
  .accordion{
    &__header{
      border-bottom:1px solid $black-soft;
      background-color: transparent;
    }
  }
  .left-menu{
    li{
      border-bottom:1px solid $black-soft;
      background-color: transparent;
      &.active{
        a, span{
          border-left: 5px solid $black-soft;
          color: $white;
          background-color: $black-soft;
        }
      }
      &:not(.active):hover{
        border-left:5px solid $black-soft;
        background-color: $black-soft;
        a{
          color: $white;
        }
      }

    }
    .left-submenu{
      li{
        border-bottom:none;
        padding-left: 0;
        &.active{
          border-left:5px solid $black-soft;
          a, span{

            color: $black-soft;
          }
        }
        a{
          background-color: transparent;
          color: $black-soft;
          border-left:none;
        }
        &:hover{
          border-left:5px solid $black-soft;
          background-color: $black-soft;
          a{
            color: $white;
          }
        }
      }
    }
  }
  .content-404__left, .content-404__title{
    color: $black-soft;
  }
  .add-button{
    color: $black-soft;
    &:before{
      background: $black-soft;
    }
  }
  .summ{
    color: $black-soft;
  }
  .content{
    .tabs.tabs-index{
      ul.tabs__caption{
        li{
          &.active{
            color: $black-soft;

            &:before{
              height: 3px;
              background-color: $black-soft;
            }
          }
          &:before{
            height: 3px;
          }
          &:after{
            height: 3px;
            background-color: $black-soft;
          }
        }
        li:hover{
          color: $black-soft;
        }
      }
    }
    .tabs__caption{
      li{
        color: $black-soft;
        &.active{
          border:1px solid $black-soft;
        }
      }
    }
  }
  .qa-block__header{
    color: $black-soft;
  }
  .contacts-item{
    &__email{
      color: $black-soft;
    }
  }
  .dms-block-img{
    filter: grayscale(100%) invert(100%);
  }
  .doc{
    filter:grayscale(100%);
  }
  .td-file, .td-price{
    width: 20%;
  }
  #nav-drill{
    li{
      a{

      }
    }
    .nav-item.active{
      background-color: $black-soft;
      & > a{
        background-color: $black-soft;
        border-left:5px solid $black-soft;
        color: $white;
        font-weight: 600;
      }
    }
    #left-menu__inner{
      ul{
        li{
          &.active{
            & > a{
              border-left:5px solid $black-soft;
              color: $black-soft;
              font-weight: bold;
            }
            .left-submenu{
              a{
                border-left:5px solid transparent;
              }
            }
          }
        }
      }
    }
  }

  .nav-item.nav-expand{
    background-color: $gray-light1;
    a{
      background: transparent;
      border-left:5px solid transparent;
    }
    .nav-expand-content {
      .nav-expand-link{
        background-color:$gray-light1 ;
      }
    }
  }
  .nav-expand-link{
    &:after{
      filter:grayscale(100%);
    }
  }
  .nav-expand-content .nav-back-link,
  .nav-expand-link{
    background-color: $gray-light1!important;
    color: $black-soft;
    &:before{
      filter: grayscale(100%);
    }
  }
  &.fz-bigger{
    .label-half-width{
      width: 100%;
    }
  }
  &.fz-biggest{
    .label-half-width{
      width: 100%;
    }
  }
  .evacuation{
    .service-inner{
      &__text-phone{
        &:before{
          top:6px;
          transform: translateY(0);
        }
      }
    }
  }
  .figured-title{
    color: $black-soft;
    border-bottom:2px solid $black-soft;
  }
  .phone-block{
    &__phone{
      color: $black-soft;
      &:before{
        filter: grayscale(100%);
      }
    }
  }
  #map{
    filter: grayscale(100%);
  }
}
.fz-bigger{
  .service-inner{
    &__icon{
      margin-bottom: 10px;
    }
  }
}
.fz-biggest{
  .service-inner{
    &__icon{
      margin-bottom: 10px;
    }
  }
  .left-text{
    width:calc(100% - 190px);
  }
  .evacuation{
    width:190px;
  }
  .search-input-content{
    .btn{
      width:auto;
      min-width: 152px;
    }
  }
}

@media (max-width: 767px) {
  .fz-biggest{
    .left-text{
      width:100%;
    }
    .evacuation{
      width:100%;
    }
  }

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .fz-bigger{
    .service-inner {
      display: block;
    }
  }
  .fz-biggest{
    .service-inner {
      display: block;
    }
  }
}
