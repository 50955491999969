.table{
  //display: table;
  width:100%;
  border: 1px solid $gray-light;
  border-bottom:none;
  margin: 20px 0;
  &.small-font{
    .td{
      font-size: 14px;
      padding: 14px 10px;
      line-height: 16px;
    }
  }
}
.th{
  background-color: $gray-light-extra;
}
.tr{
  //display: table-row;
  border-bottom: 1px solid $gray-light;
}
.td{
  //display: table-cell;
  padding: 14px 20px;
  &-name{
    width:46%;
  }
  &-price{
    width:18%;
  }
  &-file{
    width: 18%;
  }
  &__mobile-info-left{
    display: none;
  }
  &.td10{
    width: 10%;
  }
  &.td20{
    width: 20%;
  }
  &.td30{
    width: 30%;
  }
  &.td40{
    width: 40%;
  }
  &.td50{
    width: 50%;
  }
  &.td60{
    width: 60%;
  }
  &.td70{
    width: 70%;
  }
  &.td80{
    width: 80%;
  }
  &.td90{
    width: 90%;
  }
}


@media (max-width: 767px) {
  .table{
    border:none;
    *{
      font-size: 14px;
    }
  }
  .th{
    &.d-flex{
      display: none!important;
    }
  }
  .tr{
    border:1px solid $gray-light;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 2px 4px 0px $gray-light;
    -moz-box-shadow: 0 2px 4px 0px $gray-light;
    box-shadow:0 2px 4px 0px $gray-light;
    &.d-flex{
      flex-direction: column;
    }
  }
  .td{
    border-bottom:none;
    &-name{
      width: 100%;
    }
    &-file{
      width: 100%;
      &.justify-content-center{
        justify-content: flex-start!important;
      }
    }
    &-price{
      width: 100%;
    }
    &__mobile-info-left{
      display: block;
      width: 50%;
      font-weight: bold;
    }
    &.td10{
      width: 100%;
    }
    &.td20{
      width: 100%;
    }
    &.td30{
      width: 100%;
    }
    &.td40{
      width: 100%;
    }
    &.td50{
      width: 100%;
    }
    &.td60{
      width: 100%;
    }
    &.td70{
      width: 100%;
    }
    &.td80{
      width: 100%;
    }
    &.td90{
      width: 100%;
    }
  }
}