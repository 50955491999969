.header{
  &-inner{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    background: $white-transparent;
    z-index:15;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    &.white{
      background: $white;
      -webkit-box-shadow: 0 2px 10px -4px $gray;
      -moz-box-shadow: 0 2px 10px -4px $gray;
      box-shadow: 0 2px 10px -4px $gray;
    }
  }
  &-top{
    height:78px;
    border-bottom:1px solid $gray-transparent;
    .container{
      height: 100%;
      .row{
        height: 100%;
      }
    }
  }
  &-bottom{
    height:40px;
    border-bottom:1px solid $gray-transparent;
    .container{
      height: 100%;
      .row{
        height: 100%;
      }
    }
  }
  &__right{
    display: flex;
    position: relative;
  }
  &__mobile-opener{
    display: none;
    width: 28px;
    height: 28px;
    margin-left: 10px;
    background-image: url(../images/icons/menu-button.svg);
    background-size:cover;
  }
  .feedback-btn{
    white-space:nowrap;
  }
  .btn-buy-policy{
    padding: 2px 10px;
    margin-right: 20px;
    text-align: center;
  }
}
.logo{
  display: block;
  background-image: url(../images/icons/logo.svg);
  background-repeat:no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: center center;
  width: 222px;
  height: 44px;
}
.vi-toggler{
  font-size: 13px;
  padding-left: 22px;
  color: $gray-dark;
  position: absolute;
  top: 4px;
  right: 113%;
  white-space: nowrap;
  &:before{
    content: "";
    display: block;
    width: 18px;
    height: 12px;
    position: absolute;
    top:50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left:0;
    background-image: url(../images/icons/eye.svg);
    background-repeat:no-repeat;
    background-position: center center;
    -webkit-background-size: 100%;
    background-size: 100%;
  }
}
.city-phone{
  margin-right: 50px;
  text-align: right;
}
.foreign-students-phone{
  margin-right: 22px;
}
.city-choose{
  position: relative;
  z-index:1;
  span{
    font-size: 13px;
    color: $black-soft;
    position: relative;
    border-bottom:1px dashed $black-soft;
    padding-right: 16px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    &:before{
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      position: absolute;
      top: 2px;
      left: -17px;
      background-image: url(../images/icons/marker.svg);
    }
    &:after{
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 2px;
      right: 0;
      background-image: url(../images/icons/chevron.svg);
      background-size: cover;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  &:hover{
    span{
      color: $wine;
    }
  }
  &__list{
    //display: none;
    min-width: 140px;
    position: absolute;
    top:28px;
    right:0;
    max-height:0;
    overflow: hidden;
    background: $white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 10px -2px $gray;
    -moz-box-shadow:0 2px 10px -2px $gray;
    box-shadow: 0 2px 10px -2px $gray;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity:0;
    &.active-city-list{
      top:22px;
      max-height:100vh;
      opacity:1;
    }
  }
  &__item{
    padding: 12px 18px 0 18px;
    cursor: pointer;
    text-align: left;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-size: 13px;
    &:hover{
      color: $purple;
    }
    &:last-child{
      padding-bottom: 12px;
    }
  }
  &__tel{
    display: none;
  }
}
.foreign-students-phone{
  font-size: 13px;
  color: $black-soft;
}
.main-tel{
  font-family: Georgia, 'Times New Roman', serif;
  color: $wine-deep;
  font-size: 20px;
  margin-top: 4px;
  display: block;
  white-space: nowrap;
}

// main nav block

.main-nav{
  display: flex;
  align-items: center;
  height: 100%;
  > ul{
    display: flex;
    align-items: center;
    height:100%;
  }
  li{
    position: relative;
    height:100%;
    display: flex;
    align-items: center;
    a, span{
      font-size: 14px;
      color: $black-soft;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 40px;
      white-space: nowrap;
      height:100%;
      display: flex;
      align-items: center;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -ms-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      &:hover{
        color: $wine-dark;
      }
    }
  }
  li.active{
    a, span{
      color: $wine-dark;
    }
  }
  li:hover{
    .submenu{
      max-width:100vw;
      opacity:1;
      left:0;
      overflow: visible;
    }
  }
  .submenu{
    max-width:0;
    background-color: $white;
    position: absolute;
    top: 100%;
    left:-10px;
    opacity:0;
    overflow: hidden;
    z-index:5;
    -webkit-box-shadow: 0 2px 10px -2px $gray;
    -moz-box-shadow: 0 2px 10px -2px $gray;
    box-shadow: 0 2px 10px -2px $gray;
    webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    li{
      border-left:3px solid $white;
      border-bottom:1px solid rgba($gray-soft, 0.35);
      display: block;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -ms-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      a, span{
        padding: 10px 20px;
        font-size: 15px;
        color: $gray-dark;
        font-weight: 600;
        text-transform: none;
        display: block;
        width:100%;
        height:100%;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
      }
    }
    li.active{
      border-left:3px solid $wine-dark;
      background: $milky;
      a, span{
        color: $wine-dark;
      }
      &:hover{
        a{
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
    li:hover{
      border-left-color:$wine-dark;
      background: $milky;
      a{
        padding-left: 24px;
        padding-right: 16px;
      }
    }
  }
}
// auth block
.auth{
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__login{
    color: $black-soft;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    padding-left: 26px;
    position: relative;
    &:before{
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      left: 0;
      top:50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      background-image: url(../images/icons/login.svg);
      background-size: contain;
    }
  }
}
@media (max-width: 1199px){
  .vi-toggler{
    right: 102%;
    white-space: normal;
  }
}

@media(max-width: 1023px){
  .main-nav{
    display: none;
  }
  .header{
    &__mobile-opener{
      display: block;
    }
    //&-inner{
    //  position: relative;
    //  margin-bottom: -118px;
    //}
  }
}
@media(max-width: 991px){
  .header{
    &__right{
      .btn-main{
        font-size: 0;
        background-color: $gray-light;
        background-repeat:no-repeat;
        background-size: 70%;
        background-position: center center;
        background-image: url(../images/icons/settings-phone.svg);
        border:none;
        width:38px;
        height:38px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;

        &:before{
          display: none;
          border:none;
        }
        &:hover{
          border:none;
        }
      }
    }
  }
  .city-phone{
    display: none;
  }
  .foreign-students-phone{
    display: none;
  }
  .vi-toggler{
    font-size: 0;
    height: 12px;
    top: 11px;
    right: 120px;
  }
}
@media(max-width: 767px){
  .header{
    .btn-buy-policy{
      margin-right: 0;
    }
  }
  .auth__login{
    padding-left: 16px;
    &:before{
      display: none;
    }
  }
}
@media (max-width: 420px) {
  .header{
    .btn-buy-policy{
      font-size: 14px;
    }
  }
  .vi-toggler{
    right: 82px;
  }
}
@media (max-width: 360px) {
   .logo{
     max-width:60%;
   }
  .header{
    .btn-buy-policy{
      font-size: 12px;
      padding: 2px 6px;
    }
  }
}